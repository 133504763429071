import React from "react";
import { Link } from "gatsby";
import { getUser, isLoggedIn } from "../services/auth";
import AdminLayout from "../components/AdminLayout";
export default function Home() {
  return (
    <AdminLayout>
      <h1>¡Hola {isLoggedIn() ? getUser().email : null}!</h1>
      <p>
        {isLoggedIn() ? (
          <>
            You are logged in, so check your{" "}
            <Link to="/app/profile">profile</Link>
          </>
        ) : (
          <>
            Tienes que <Link to="/app/login">iniciar sesión</Link> para ver el
            contenido restringido.
          </>
        )}
      </p>
    </AdminLayout>
  );
}
// import * as React from "react";
// import AdminLayout from "../components/AdminLayout";
// import Login from "../views/login";

// // styles
// const pageStyles = {
//   color: "#232129",
//   padding: 96,
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
// };

// // markup
// const IndexPage = () => {
//   const [isLogged, setIsLogged] = React.useState(false);

//   return isLogged ? (
//     <AdminLayout>
//       <main style={pageStyles}></main>
//     </AdminLayout>
//   ) : (
//     <Login setIsLogged={setIsLogged} />
//   );
// };

// export default IndexPage;
